/* eslint-disable @typescript-eslint/no-explicit-any */
import {createPropertySelectors, createSelector, Selector} from '@ngxs/store';
import {translateObject} from '@shared/shared-module/pipes/translate-object.pipe';
import {AppStateSelectors} from '@shared/shared-module/stores/selectors/app.state.selectors';
import {addToDate} from '@shared/shared-module/utils/date-time.utils';
import {getSeasonTranslation} from '@shared/shared-module/utils/season-utils';
import {GetByIdFn} from '@shared/shared-module/utils/store.utils';
import {getTrackingStateTranslation, TranslationString} from '@shared/shared-module/utils/translation.utils';
import {isEqual} from 'lodash';
import {
  CodeDto,
  DetailsLeaveDto,
  DetailsShiftFdtUniversityDto,
  DetailsShiftGadDto,
  DivisionScheduleDto,
  DocumentInfoDto,
  Language,
  RequestDetailDto,
  RequestDto,
  RequestDutyInfoDto,
  RequestState,
  TrackingState
} from '../../core/api/generated/msa-duty-service';
import {EditRequestStateModel} from '../models/edit-request-state.model';
import {EditRequestState} from '../states/edit-request.state';
import {CodeListStateSelectors} from './code-list.state.selectors';
import {RequestsStateSelectors} from './requests.state.selectors';

export type LanguageSummary = {
  text: string;
  present: boolean;
};

export interface Translatable {
  text: TranslationString;
  params?: unknown;
}

export interface RequestIncompleteNotice {
  title: TranslationString;
  reason: string;
  decisionMaker: string | null;
  respondedAt: string | null;
  documents: DocumentInfoDto[];
}

export interface FractionData {
  season: TranslationString;
  startDate: Date | string | undefined;
  endDate: Date | string | undefined;
  startWeeks: number;
  endWeeks: number;
}

/**
 * This class contains selectors that should only be relevant for editing a current request.
 * General functionality is handeled in {@see RequestsStateSelectors}.
 */
export class EditRequestStateSelectors {
  static slices = createPropertySelectors<EditRequestStateModel>(EditRequestState);

  @Selector([EditRequestStateSelectors.slices.userFormData, EditRequestStateSelectors.getCurrentEditingRequest])
  static hasUnsavedChanges(requestDetail: RequestDetailDto | null, request: RequestDto): boolean {
    if (!requestDetail) return false;
    return !isEqual(requestDetail, request.requestDetail);
  }

  @Selector([EditRequestStateSelectors.slices.requestId, RequestsStateSelectors.getRequestByIdFn])
  static getCurrentEditingRequest(
    requestId: string,
    getRequestByIdFn: GetByIdFn<RequestDto | null>
  ): RequestDto | null {
    return getRequestByIdFn(requestId);
  }

  @Selector([EditRequestStateSelectors.getCurrentEditingRequest])
  static getDocuments(request: RequestDto): DocumentInfoDto[] {
    if (!request) return [];

    return request.requestDetail.documents;
  }

  @Selector([EditRequestStateSelectors.slices.requestId, RequestsStateSelectors.getResponsesFn])
  static getResponses(requestId: string | null, getResponsesFn: GetByIdFn<DocumentInfoDto[]>): DocumentInfoDto[] {
    if (!requestId) return [];
    return getResponsesFn(requestId);
  }

  @Selector([EditRequestStateSelectors.getCurrentEditingRequest])
  static getTrackingState(request: RequestDto): TranslationString {
    if (!request) return '';

    return getTrackingStateTranslation(request.trackingState);
  }

  @Selector([EditRequestStateSelectors.getCurrentEditingRequest])
  static getRequestType(request: RequestDto): string {
    if (!request) return '';

    return request.requestType;
  }

  @Selector([EditRequestStateSelectors.getCurrentEditingRequest, RequestsStateSelectors.getAffectedDutyFn])
  static getAffectedDuty(request: RequestDto, getAffectedDutyFn: GetByIdFn<RequestDutyInfoDto | undefined>) {
    if (!request) return null;

    return getAffectedDutyFn(request.id);
  }

  static getFractionPair(
    requestId: string
  ): (...states: any[]) => [FractionData | undefined, FractionData | undefined] {
    return createSelector(
      [RequestsStateSelectors.getRequestById(requestId), EditRequestStateSelectors.slices.divisionSchedules],
      (request: RequestDto, divisionSchedules: DivisionScheduleDto[]) => {
        if (divisionSchedules.length === 0) {
          return [undefined, undefined];
        }
        const requestDetail = request.requestDetail as DetailsShiftGadDto;

        const foundSchedule1 = divisionSchedules.find(
          schedule =>
            schedule.year === requestDetail.replacementYear1 && schedule.season === requestDetail.replacementSeason1
        );

        const foundSchedule2 = divisionSchedules.find(
          schedule =>
            schedule.year === requestDetail.replacementYear2 && schedule.season === requestDetail.replacementSeason2
        );

        if (!foundSchedule1) {
          throw new Error('Division schedule one  not found');
        }
        const fractionData1 = {
          season: getSeasonTranslation(requestDetail.replacementSeason1),
          startDate: foundSchedule1.startDate,
          endDate: requestDetail.fraction
            ? addToDate(foundSchedule1.endDate!, requestDetail.weeksTillFraction - foundSchedule1.weeks!, 1)
            : foundSchedule1.endDate,
          startWeeks: 1,
          endWeeks: requestDetail.weeksTillFraction
        };

        if (!requestDetail.fraction) return [fractionData1, undefined];
        if (!foundSchedule2) {
          throw new Error('Division schedule two not found');
        }
        const fractionData2 = {
          season: getSeasonTranslation(requestDetail.replacementSeason2),
          startDate: addToDate(foundSchedule2.startDate!, requestDetail.weeksTillFraction, 0),
          endDate: foundSchedule2.endDate,
          startWeeks: fractionData1.endWeeks + 1,
          endWeeks: foundSchedule2.weeks!
        };

        return [fractionData1, fractionData2];
      }
    );
  }

  static isUniversityDetail = (requestDetail: RequestDetailDto): requestDetail is DetailsShiftFdtUniversityDto => {
    return (
      requestDetail._type === 'DetailsShiftFdtUniversityDto' || requestDetail._type === 'DetailsShiftGadUniversityDto'
    );
  };

  static isGadDetail = (requestDetail: RequestDetailDto): requestDetail is DetailsShiftGadDto => {
    return requestDetail._type === 'DetailsShiftGadDto' || requestDetail._type === 'DetailsShiftGadUniversityDto';
  };

  static isLeaveDetail = (requestDetail: RequestDetailDto): requestDetail is DetailsLeaveDto => {
    return requestDetail._type === 'DetailsLeaveDto';
  };

  @Selector([
    EditRequestStateSelectors.getCurrentEditingRequest,
    CodeListStateSelectors.getUniversityByCode,
    AppStateSelectors.slices.language
  ])
  static getRequestIncompleteNotice(
    request: RequestDto | null,
    getUniversityByCode: GetByIdFn<CodeDto>,
    language: Language
  ): RequestIncompleteNotice | null {
    if (!request) return null;
    const requestDetail = request.requestDetail;
    if (request.state !== RequestState.Incomplete) return null;

    const commonNotice = {
      reason: request.responseData.responseReason!,
      decisionMaker: request.responseData.decisionMaker!,
      respondedAt: request.responseData.decisionDateTime!,
      documents: request.responseData.responses ?? []
    };

    if (EditRequestStateSelectors.isLeaveDetail(requestDetail)) {
      return {
        ...commonNotice,
        title: 'i18n.leave.overview.notificationIncompleted',
        respondedAt: request.responseData.decisionDateTime!
      };
    } else {
      if (EditRequestStateSelectors.isUniversityDetail(requestDetail)) {
        const universityCodeDto = getUniversityByCode(requestDetail.university.university);
        return {
          ...commonNotice,
          title:
            request.trackingState === TrackingState.University
              ? 'i18n.user-info.paragraph-header-university'
              : 'i18n.user-info.paragraph-header-army',
          decisionMaker:
            request.trackingState === TrackingState.University && universityCodeDto
              ? translateObject(universityCodeDto?.descriptionDto, language)
              : null
        };
      }
      return {
        ...commonNotice,
        title: 'i18n.user-info.paragraph-header-army',
        decisionMaker: null
      };
    }
  }
}
