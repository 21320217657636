import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {
  DutyMsaStateDto,
  RankAddition,
  RequestState,
  RequestType,
  SeasonDto
} from '../core/api/generated/msa-duty-service';

export function getReplacementTranslation(fraction: boolean): TranslationString {
  if (fraction) {
    return 'i18n.duty-relocation-form.gad.replacement-period.optionDoFraction';
  } else {
    return 'i18n.duty-relocation-form.gad.replacement-period.optionDoAtOnce';
  }
}

export type StatusFields<ColorType> = {statusText: TranslationString; backgroundColor: ColorType; textColor?: string};

function getRequestStatusFields(requestState: RequestState | RequestState): StatusFields<string> {
  switch (requestState) {
    case RequestState.Open || RequestState.Open:
      return {statusText: 'i18n.requests.states.open', backgroundColor: 'tw-bg-black-light-5'};
    case RequestState.Submitted || RequestState.Submitted:
      return {statusText: 'i18n.requests.states.submitted', backgroundColor: 'tw-bg-russett'};
    case RequestState.Accepted || RequestState.Accepted:
      return {statusText: 'i18n.requests.states.accepted', backgroundColor: 'tw-bg-olive'};
    case RequestState.Rejected || RequestState.Rejected:
      return {statusText: 'i18n.requests.states.rejected', backgroundColor: 'tw-bg-vermillion'};
    case RequestState.Incomplete || RequestState.Incomplete:
      return {statusText: 'i18n.requests.states.incomplete', backgroundColor: 'tw-bg-tuscany'};
    default:
      throw new Error(`Unknown request state ${requestState}!`);
  }
}

function getRequestTypeText(requestType: RequestType): TranslationString {
  switch (requestType) {
    case RequestType.Leave:
      return 'i18n.requests.label.leave';
    case RequestType.Reconsideration:
      return 'i18n.requests.label.weg';
    case RequestType.Shift:
      return 'i18n.requests.label.dvs';
  }
}

function getDutyStatusFields(msaState: DutyMsaStateDto): StatusFields<string> {
  switch (msaState) {
    case DutyMsaStateDto.CallOfDutySent:
      return {statusText: 'i18n.duties.states.callOfDutySent', backgroundColor: 'tw-bg-olive'};
    case DutyMsaStateDto.Planned:
      return {statusText: 'i18n.duties.states.planned', backgroundColor: 'tw-bg-tuscany'};
    case DutyMsaStateDto.Completed:
      return {statusText: 'i18n.duties.states.completed', backgroundColor: 'tw-bg-black-light-5'};
    case DutyMsaStateDto.Booked:
      return {
        statusText: 'i18n.duties.states.booked',
        backgroundColor: 'tw-bg-black-light-7.5',
        textColor: 'tw-text-black'
      };
    case DutyMsaStateDto.ServiceNoticeSent:
      return {statusText: 'i18n.duties.states.serviceNoticeSent', backgroundColor: 'tw-bg-russett'};
  }
  throw new Error(`Unknown duty state ${msaState}!`);
}

export function getRankAdditionText(rankAddition: RankAddition): TranslationString {
  switch (rankAddition) {
    case RankAddition.J:
      return 'i18n.user.banner.rank-addition.j';
    case RankAddition.Rkd:
      return 'i18n.user.banner.rank-addition.rkd';
    default:
      return '';
  }
}

export function getSeasonText(season: SeasonDto): TranslationString {
  switch (season) {
    case SeasonDto.Winter:
      return 'i18n.duties.gad.season.winter';
    case SeasonDto.Spring:
      return 'i18n.duties.gad.season.spring';
    case SeasonDto.Summer:
      return 'i18n.duties.gad.season.summer';
    case SeasonDto.Autumn:
      return 'i18n.duties.gad.season.autumn';
    default:
      return '';
  }
}

// export functions unsed name, to group them
// there might be multiple translation utils in every submodule
export const RequestTranslationsUtils = {
  getStatusFields: getRequestStatusFields,
  getTypeText: getRequestTypeText
};

export const DutyTranslationsUtils = {
  getStatusFields: getDutyStatusFields
};
