import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Output, computed, inject, input, model} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {KeyValueComponent} from '@shared/shared-module/components/key-value/key-value.component';
import {MsaFileOverviewComponent} from '@shared/shared-module/components/msa-file-overview/msa-file-overview.component';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {GERMAN_DATE_TIME, MONTH_YEAR_FORMAT} from '@shared/shared-module/utils/date-time.utils';
import {findByCode} from '@shared/shared-module/utils/find.utils';
import {DutyContextComponent} from 'projects/admin-query/src/app/components/duty-context/duty-context.component';
import {
  CodeDto,
  DetailsShiftFdtDto,
  DetailsShiftGadDto,
  DocumentInfoDto,
  RequestDto
} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';
import {SummaryItem} from 'projects/admin-query/src/app/models/summary-items.model';
import {DutyStateSelectors} from 'projects/admin-query/src/app/stores/selectors/duty.state.selectors';
import {EditRequestStateSelectors} from 'projects/admin-query/src/app/stores/selectors/edit-request-state.selectors';
import {GadSubstituionTimeSummaryComponent} from '../gad-substitution-time/gad-substitution-time-summary.component';
import {SubstituionTimeSummaryComponent} from '../substitution-time/substitution-time-summary.component';

/**
 * This component is only needed to provide the correct list
 * of steps to be displayed.
 */
@Component({
  selector: 'app-shift-request-summary',
  templateUrl: './shift-request-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    TranslateObjectPipe,
    MsaFileOverviewComponent,
    DutyContextComponent,
    MatOptionModule,
    SafeTranslatePipe,
    SafeTranslateDirective,
    KeyValueComponent,
    SubstituionTimeSummaryComponent,
    GadSubstituionTimeSummaryComponent
  ]
})
export class ShiftRequestSummaryComponent {
  public request = input.required<RequestDto>();
  public reasonTypes = input.required<CodeDto[]>();
  public showDutyContext = input(false);
  public isGad = computed<boolean>(() => EditRequestStateSelectors.isGadDetail(this.request().requestDetail));

  @Output()
  public downloadAttachment = new EventEmitter<DocumentInfoDto>();

  @Output()
  public downloadResponse = new EventEmitter<DocumentInfoDto>();

  private store = inject(Store);

  public summaryItems = model<SummaryItem[]>([]);

  public duty = computed(() => this.store.selectSnapshot(DutyStateSelectors.getDutyInfoByIdFn)(this.request().dutyId)!);

  readonly requestDetails = computed<DetailsShiftFdtDto | DetailsShiftGadDto>(() => {
    if (this.isGad()) {
      return this.request().requestDetail as DetailsShiftGadDto;
    } else {
      return this.request().requestDetail as DetailsShiftFdtDto;
    }
  });
  readonly requestDetailsFdt = computed<DetailsShiftFdtDto>(() => this.request().requestDetail as DetailsShiftFdtDto);
  readonly requestDetailsGad = computed<DetailsShiftGadDto>(() => this.request().requestDetail as DetailsShiftGadDto);

  readonly reasonTypeCode = computed(
    () => this.reasonTypes()?.find(findByCode(this.requestDetails().reasonType)) ?? null
  );

  readonly MONTH_YEAR_FORMAT = MONTH_YEAR_FORMAT;
  readonly GERMAN_DATE_TIME = GERMAN_DATE_TIME;
}
